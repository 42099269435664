import { Divider, Layout, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import Accordion from 'react-native-collapsible/Accordion';
import AssessmentHeader from './AssessmentHeader';
import { CoachInfo } from 'src/core/types/CoachInfo';
import Coaches from './CoachList';
import { ErrorModal } from '../shared/ErrorModal';
import { HealthMarkerReportListItem } from 'src/core/types/HealthMarkerReportList';
import IndividualAssessmentList from './IndividualAssessmentList';
import Spinner from 'react-native-loading-spinner-overlay';
import moment from 'moment';
import { UIHelper as uh } from '../../core';
import { useAssessments } from 'src/core/hooks/useAssessments';

// import AssessmentPillars from './AssessmentPillars';

interface IYourAssessmentsProps extends ViewProps {
  btnDetailAssessmentClick: (assessmentId: string) => void;
  refreshing: boolean;
  finishRefreshing: () => void;
}

const YourAssessments = ({ btnDetailAssessmentClick, refreshing, finishRefreshing }: IYourAssessmentsProps) => {
  const styleContainer = StyleSheet.create({
    container: {
      flex: 1
    },
    header: {
      padding: uh.h2DP(16),
      marginTop: uh.h2DP(8),
      borderRadius: 8
    },
    activeHeader: {
      padding: uh.h2DP(16),
      marginTop: uh.h2DP(8),
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8
    },
    content: {
      paddingBottom: uh.h2DP(16),
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8
    },
    pillars: { marginTop: uh.h2DP(16), paddingLeft: uh.w2DP(16), paddingRight: uh.w2DP(16) },
    individualAssementList: {
      marginTop: uh.h2DP(24),
      paddingLeft: uh.w2DP(16),
      paddingRight: uh.w2DP(16)
    },
    divider: { marginTop: uh.h2DP(39), marginBottom: uh.h2DP(39) },
    coaches: { marginTop: uh.h2DP(24), paddingLeft: uh.w2DP(16), paddingRight: uh.w2DP(16), marginBottom: uh.h2DP(16) }
  });

  const [activeSections, setActiveSections] = useState([]);

  const [assessmentsGroup, groupNames, totalAssessments, loading, error] = useAssessments(refreshing, finishRefreshing);

  const btnManageClick = () => {};

  const renderHeader = (assessmentType: string, index: number, isActive: boolean) => {
    const assessmentName = (assessmentsGroup.get(assessmentType) as HealthMarkerReportListItem[])[0]
      .assessmentName as string;

    const hmReportItem = (assessmentsGroup.get(assessmentType) as HealthMarkerReportListItem[])[0];
    const latestAssessmentDate = moment(
      hmReportItem.assessmentName == 'Wearables' ? hmReportItem.updatedOn : hmReportItem.measuredDate
    ).format('DD MMM YYYY HH:mm');

    return (
      <AssessmentHeader
        key={`header-index-${index}`}
        assessmentType={assessmentName}
        latestAssessmentDate={latestAssessmentDate}
        numberOfAssessments={(assessmentsGroup?.get(assessmentType) as HealthMarkerReportListItem[]).length}
        isActive={isActive}
      />
    );
  };

  const renderContent = (assessmentType: string) => {
    const coaches: CoachInfo[] = [];
    assessmentsGroup?.get(assessmentType)?.forEach((item) => {
      if (coaches.findIndex((element) => element.name === item.coachName) === -1 && item.coachName) {
        coaches.push({ name: item.coachName, permission: 'Access Everything' });
      }
    });

    return (
      <Layout style={styleContainer.content}>
        <Divider />
        {/* <AssessmentPillars caption="Pillars" pillars={assessment.pillars} style={styleContainer.pillars} size="large" /> */}
        <IndividualAssessmentList
          style={styleContainer.individualAssementList}
          btnClickHandler={btnDetailAssessmentClick}
          assessments={assessmentsGroup?.get(assessmentType) as HealthMarkerReportListItem[]}
          caption="Individual reports"
        />
        {coaches && coaches.length > 0 && (
          <Coaches
            style={styleContainer.coaches}
            caption="Practitioners"
            coaches={coaches}
            btnMessage="Manage"
            btnClickHandler={btnManageClick}
          />
        )}
      </Layout>
    );
  };

  const updateSections = (sections: any) => {
    setActiveSections(sections.includes(undefined) ? [] : sections);
  };

  if (loading && !refreshing) {
    return <Spinner visible={true} />;
  }

  return (
    <View>
      <Text category="s1">
        My reports{' '}
        <Text category="p1" appearance="hint">
          {totalAssessments}
        </Text>
      </Text>
      <Accordion
        containerStyle={{ marginTop: 8 }}
        underlayColor="transparent"
        activeSections={activeSections}
        sections={groupNames}
        expandMultiple={true}
        renderHeader={renderHeader}
        renderContent={renderContent}
        onChange={updateSections}
      />
      {error && <ErrorModal visible={true} message={error} />}
    </View>
  );
};

export default YourAssessments;
