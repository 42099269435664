import * as AuthScreen from '../../screens/auth';
import * as CustomisationScreen from '../../screens/customisation';
import * as MainScreens from '../../screens/main';
import * as NotificationScreen from 'src/screens/notification';
import * as PillarScreens from '../../screens/pillar';
import * as SettingScreens from '../../screens/settings';
import * as SleepStatScreen from 'src/screens/sleepStat';

import { CardStyleInterpolators, StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import { Config, UIHelper as uh, UserAttributes } from '../../core';
import React, { useEffect, useMemo, useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';

import ActionNavigation from './ActionNavigation';
import Brands from 'brands.json';
import ChatNavigation from './ChatNavigation';
import HeaderLeft from './components/HeaderLeft';
import HeaderRight from './components/HeaderRight';
import { InfoModal } from 'src/components/shared/InfoModal';
import MyMarkersNavigation from './MyMarkersNavigation';
import { NotConnected } from '../../components/shared';
import { Platform } from 'react-native';
import { RootNavigationParamList } from 'src/core/types/RootNavigationParamList';
import ScanLoadingScreen from 'src/components/scan/ScanLoadingScreen';
import { ApplicationState, useAppStore } from 'src/core/store';
import { useGetUserAttributes, useSetAuthLoading } from 'src/core/hooks/useUser';
import { useIsMarkersInitLoading } from 'src/core/hooks/useMarkers';
import { useNavigation } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import { InputEmailModal } from 'src/components/shared/InputEmailModal';
import UserService from 'src/api/user';
import { Auth } from 'aws-amplify';

//props
interface RootNavigationProps {
  isFirstLoad: boolean;
  isLoggedIn: boolean;
  isRequirePublicEmail: boolean;
}

const RootNavigation = ({ isFirstLoad, isLoggedIn, isRequirePublicEmail }: RootNavigationProps) => {
  const [visibleProfileModal, setVisibleProfileModal] = useState<boolean>(false);
  const navigation = useNavigation();
  const setIsAuthLoading = useSetAuthLoading();
  const setRequirePublicEmail = useAppStore((state: ApplicationState) => state.setRequirePublicEmail);
  const setUserLogged = useAppStore((state: ApplicationState) => state.setUserLogged);

  // context
  const Stack = createStackNavigator<RootNavigationParamList>();
  const userAttributes = useGetUserAttributes();
  const netInfo = useNetInfo();
  const isMarkersInitLoading = useIsMarkersInitLoading();

  // styles
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);
  const condColors = {
    header: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100'),
    headerTintColor: uh.getHex(th, theme, 'color-basic-900', 'color-basic-100')
  };

  const isCompletedProfile = useMemo(() => {
    return !Object.entries(userAttributes).some(
      (entry) =>
        (entry[0] !== 'picture' && entry[0] !== 'publicEmail' && (entry[1] === undefined || entry[1] === '')) ||
        (userAttributes.email?.includes('privaterelay.appleid.com') && userAttributes.publicEmail == undefined)
    );
  }, [userAttributes]);

  const headerScreenOptions = (
    title: string,
    showHeader?: boolean,
    showHeaderLeft?: boolean,
    showHeaderRight?: boolean
  ): StackNavigationOptions => {
    const baseScreenOptions: StackNavigationOptions = {
      headerShown: showHeader,
      headerTitle: () => (
        <Text status="basic" category="s2">
          {title}
        </Text>
      ),
      title: title,
      headerLeft: () => (showHeaderLeft ? <HeaderLeft /> : null),
      headerRight: () => (showHeaderRight ? <HeaderRight /> : null),
      headerTintColor: condColors.headerTintColor,
      headerStyle: {
        backgroundColor: condColors.header
      },
      headerTitleAlign: 'center',
      animationEnabled: false,
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
    };
    return baseScreenOptions;
  };

  useEffect(() => {
    if (isLoggedIn && !isMarkersInitLoading && !isCompletedProfile) {
      setVisibleProfileModal(true);
    }
  }, [isCompletedProfile, isMarkersInitLoading, isLoggedIn, isRequirePublicEmail]);

  if (netInfo.isConnected === false) {
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="NotConnected"
          options={{ ...headerScreenOptions('NotConnected', false) }}
          component={NotConnected}
        />
      </Stack.Navigator>
    );
  }

  // view
  return (
    // Re-aligned navigators as "initialRouteName" on Stack.Navigator fails to switch context of view stack on signOut
    // Note:If more complex flows are added will need refactor out and load stacks
    <>
      {isRequirePublicEmail == false && (
        <Stack.Navigator initialRouteName="Home">
          {!isLoggedIn ? (
            <>
              {/* first load scenario before use signs up*/}
              {isFirstLoad && (
                <Stack.Screen
                  name="PreRegister"
                  options={{
                    ...headerScreenOptions('PreRegister', true, false)
                  }}
                  component={AuthScreen.PreRegisterScreen}
                />
              )}
              {/* login will be default screen outside first load condition*/}
              <Stack.Screen
                name="Login"
                options={{
                  ...headerScreenOptions('Login', true, false)
                }}
                component={AuthScreen.LoginScreen}
              />
              {/* <Stack.Screen
              name="Register"
              options={{
                ...headerScreenOptions('Register', true, false)
              }}
              component={AuthScreen.RegisterScreen}
            /> */}
              {/* <Stack.Screen
              name="ConfirmationRegisterOTP"
              options={{ ...headerScreenOptions('One Time Code', true, true) }}
              component={AuthScreen.ConfirmationRegisterOTPScreen}
            />
            <Stack.Screen
              name="ConfirmationRegister"
              options={{ ...headerScreenOptions('Sign Up', true, true) }}
              component={AuthScreen.ConfirmationRegisterScreen}
            />
            <Stack.Screen
              name="ResetPassword"
              options={{
                ...headerScreenOptions('Reset Password', true, true)
              }}
              component={AuthScreen.ResetPasswordScreen}
            />
            <Stack.Screen
              name="PasswordOTP"
              options={{ ...headerScreenOptions('One Time Code', true, true) }}
              component={AuthScreen.ResetPasswordOTPScreen}
            />
            <Stack.Screen
              name="ConfirmationReset"
              options={{ ...headerScreenOptions('Reset Password', true, false) }}
              component={AuthScreen.ConfirmationResetScreen}
            /> */}
              {Config.DEVELOPMENT_MODE && (
                <Stack.Screen
                  name="DeveloperSettings"
                  options={{ ...headerScreenOptions('Developer Settings', true, true, true) }}
                  component={SettingScreens.DeveloperSettings}
                />
              )}
            </>
          ) : (
            // logged in stack with tabs and settings

            <>
              <Stack.Screen
                name="Home"
                options={{ ...headerScreenOptions('', true, true, true) }}
                component={MainScreens.HomeScreen}
              />
              <Stack.Screen
                name="Actions"
                options={{ ...headerScreenOptions('Actions', false, true, true) }}
                component={ActionNavigation}
              />
              <Stack.Screen
                name="Assessments"
                options={{ ...headerScreenOptions('Reports', true, true, true) }}
                component={MainScreens.ProfileMeAssessmentScreen}
              />
              <Stack.Screen
                name="Kali"
                options={{ ...headerScreenOptions(Brands.chatbot_name, true, true, true) }}
                component={MainScreens.KaliChatScreen}
              />
              <Stack.Screen
                name="ScanGettingStarted"
                options={{ ...headerScreenOptions('Scan', true, true, true) }}
                component={MainScreens.ScanGettingStarted}
              />
              <Stack.Screen
                name="AssessmentDetail"
                options={{
                  ...headerScreenOptions('Report details', true, true)
                }}
                component={MainScreens.AssessmentDetailScreen}
              />
              <Stack.Screen
                name="Pillar"
                options={{
                  ...headerScreenOptions('Pillar', true, true)
                }}
                component={PillarScreens.PillarScreen}
              />
              <Stack.Screen
                name="SleepStat"
                options={{
                  ...headerScreenOptions('Sleep Stat', true, true)
                }}
                component={SleepStatScreen.SleepStatScreen}
              />
              <Stack.Screen
                name="FaceScanInput"
                options={{
                  ...headerScreenOptions('Face Scan Input', true, true)
                }}
                component={MainScreens.FaceScanInputScreen}
              />
              <Stack.Screen
                name="FaceScanGuide"
                options={{
                  ...headerScreenOptions('Face Scan', true, true)
                }}
                component={MainScreens.FaceScanGuideScreen}
              />
              <Stack.Screen
                name="BodyScanInput"
                options={{
                  ...headerScreenOptions('Body Scan Input', true, true)
                }}
                component={MainScreens.BodyScanInputScreen}
              />
              <Stack.Screen
                name="BodyScanGuide"
                options={{
                  ...headerScreenOptions('Body Scan', true, true)
                }}
                component={MainScreens.BodyScanGuideScreen}
              />
              <Stack.Screen
                name="BloodworkGuide"
                options={{
                  ...headerScreenOptions('Lab Data', true, true)
                }}
                component={MainScreens.BloodworkGuideScreen}
              />
              <Stack.Screen
                name="HRVScanGuide"
                options={{
                  ...headerScreenOptions('HRV Scan', true, true)
                }}
                component={MainScreens.HRVScanGuideScreen}
              />
              <Stack.Screen
                name={'Customisation' as any}
                options={{
                  ...headerScreenOptions('Customisation', true, true, true)
                }}
                component={CustomisationScreen.CustomisationScreen}
              />
              <Stack.Screen
                name="MyMarkers"
                options={{ ...headerScreenOptions('My Markers', true, true, true) }}
                component={MyMarkersNavigation}
              />
              <Stack.Screen
                name={'Chat'}
                options={{ ...headerScreenOptions('Chat', false, true, true) }}
                component={ChatNavigation}
              />
              <Stack.Screen
                name={'Notifications'}
                options={{ ...headerScreenOptions('Notifications', true, true, true) }}
                component={NotificationScreen.NotificationScreen}
              />

              {Platform.OS != 'web' && (
                <Stack.Screen
                  name="HRVScan"
                  options={{
                    ...headerScreenOptions('HRV Scan', true, true)
                  }}
                  // eslint-disable-next-line
                  component={MainScreens.HRVScanScreen}
                />
              )}
              {Platform.OS != 'web' && (
                <Stack.Screen
                  name="HRVProcessing"
                  options={{
                    ...headerScreenOptions('HRV Scan Processing', true, true)
                  }}
                  // eslint-disable-next-line
                  component={MainScreens.HRVProcessingScreen}
                />
              )}
              {Platform.OS !== 'web' && (
                <Stack.Screen name="ScanLoadingScreen" options={{ headerShown: false }} component={ScanLoadingScreen} />
              )}
              <Stack.Screen
                name="Settings"
                options={{
                  ...headerScreenOptions('Menu', true, true, true)
                }}
                component={SettingScreens.MainMenuScreen}
              />
              <Stack.Screen
                name="ProfileSettings"
                options={{ ...headerScreenOptions('Profile', true, true, true) }}
                component={SettingScreens.ProfileScreen}
              />
              <Stack.Screen
                name="ProfileSignUp"
                options={{ ...headerScreenOptions('Profile', true) }}
                initialParams={{ shouldRedirect: true }}
                component={SettingScreens.ProfileScreen}
              />
              <Stack.Screen
                name="Subscriptions"
                options={{ ...headerScreenOptions('Subscriptions', true, true, true) }}
                component={SettingScreens.SubscriptionsScreen}
              />
              <Stack.Screen
                name="AfterPurchaseSubscriptionScreen"
                options={{ ...headerScreenOptions('Subscriptions', true, true, true) }}
                component={SettingScreens.AfterPurchaseSubscriptionScreen}
              />
              <Stack.Screen
                name="DataSharing"
                options={{ ...headerScreenOptions('Data sharing', true, true, true) }}
                component={SettingScreens.DataSharing}
              />
              <Stack.Screen
                name="Feedback"
                options={{ ...headerScreenOptions('Feedback', true, true, true) }}
                component={SettingScreens.FeedbackScreen}
              />
              <Stack.Screen
                name="ConnectedDevices"
                options={{ ...headerScreenOptions('Connected Devices', true, true, true) }}
                component={SettingScreens.ConnectedDevicesScreen}
              />
              <Stack.Screen
                name="AboutUs"
                options={{ ...headerScreenOptions('About Us', true, true, true) }}
                component={SettingScreens.AboutScreen}
              />
              {Config.DEVELOPMENT_MODE && (
                <Stack.Screen
                  name="DeveloperSettings"
                  options={{ ...headerScreenOptions('Developer Settings', true, true, true) }}
                  component={SettingScreens.DeveloperSettings}
                />
              )}
            </>
          )}
          <>
            <Stack.Screen
              name="SharingApprove"
              options={{
                headerShown: false
              }}
              component={MainScreens.TenantDataSharingScreen}
            />
          </>
        </Stack.Navigator>
      )}
      <InfoModal
        visible={visibleProfileModal}
        message={'Please complete your personal profile. This is needed for the app to work well.'}
        closeBtnClick={() => {
          setVisibleProfileModal(false);
          navigation.navigate('ProfileSignUp');
        }}
      />
      {isRequirePublicEmail == true && (
        <InputEmailModal
          visible={true}
          message={
            'We have detected that you are using private email relay. For the app to work well, please provide the email address you use for your AppleID.'
          }
          closeBtnClick={async () => {
            const user = await Auth.currentAuthenticatedUser();
            const userInfo: UserAttributes = {
              name: user.attributes.name,
              nickname: user.attributes.nickname,
              email: user.attributes.email,
              gender: user.attributes.gender,
              birthdate: user.attributes.birthdate,
              sub: user.attributes.sub,
              height: undefined,
              weight: undefined,
              smoker: undefined,
              bloodpressureMedication: undefined,
              hypertension: undefined,
              diabetic: undefined,
              activityLevel: undefined
            };
            setIsAuthLoading(true);
            await UserService.getUserInfo().catch(() => UserService.addUser(userInfo));
            setUserLogged(true);

            setRequirePublicEmail(false);
          }}
        />
      )}
    </>
  );
};

export default RootNavigation;
