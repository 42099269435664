import { Animated, Platform, StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { Divider, Layout, Text, useTheme } from '@ui-kitten/components';
import { Pillar, UIHelper as uh } from '../../../core';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  useAddFavoriteMarkers,
  useGetFavoriteMarkers,
  useRemoveFvoriteMarker,
  useSetFavoriteMarkers
} from 'src/core/hooks/useMyMarkers';

import AddIcon from 'src/features/markers/components/icons/AddIcon';
import AssessmentPillars from '../../profileMeAssessment/AssessmentPillars';
import AssessmentScore from '../../profileMeAssessment/assessmentDetail/AssessmentScore';
import BarChart from '../../profileMeAssessment/assessmentDetail/BarChart';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import DeleteFavoriteBottomSheet from 'src/features/markers/components/DeleteFavoriteBottomSheet';
import DeleteIcon from 'src/features/markers/components/icons/DeleteIcon';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { HealthMarkerHistoryValue } from 'src/core/types/HealthMarkerHistory';
import HistoryFilter from './HistoryFilter';
import IndividualAssessmentList from '../../profileMeAssessment/IndividualAssessmentList';
import { KeyboardAvoidingView } from '../../shared';
import { LearnMore } from '../../home';
import ModalHeader from '../ModalHeader';
import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import ScoreChart from '../biomarkerDetail/ScoreChart';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import moment from 'moment';
import { toPreciseNumber } from 'src/core/helpers/NumberHelper';
import { useMarkerScoreHistoriesEffect } from 'src/core/hooks/useMarkerScoreHistories';

//props
interface IBiomarkerDetailProps extends ViewProps {
  biomarker: NewHealthMarker;
  btnBackHandler: () => void;
  btnLearnMoreHandler: () => void;
  btnChatMoreHandler: () => void;
  btnDetailAssessmentClick: (assessmentId: string) => void;
}

const ANIMATE_DURATION = 500; //ms

//styles
const styleContainer = StyleSheet.create({
  screenContainer: {
    padding: uh.h2DP(16),
    paddingTop: 0,
    minHeight: uh.height() - uh.topPos()
  },
  content: {
    marginTop: 16,
    borderRadius: 8,
    paddingTop: 16,
    paddingBottom: 16
  },
  animatedViewContainer: {
    height: uh.height() - (Platform.OS === 'ios' ? uh.topPos() : 0),
    position: 'absolute',
    bottom: Platform.OS === 'android' ? 0 : undefined,
    alignSelf: 'center',
    width: uh.currentViewPort()
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: uh.h2DP(16)
  },
  name: { marginLeft: uh.h2DP(16), marginRight: uh.h2DP(46) },
  score: { marginTop: uh.h2DP(10), marginLeft: uh.h2DP(16), marginRight: uh.h2DP(16) },
  divider: { marginTop: uh.h2DP(16) },
  summary: { marginTop: uh.h2DP(13), marginLeft: uh.h2DP(32) },
  pillars: {
    flex: 1,
    marginTop: uh.h2DP(16),
    marginLeft: uh.h2DP(16),
    marginRight: uh.h2DP(16),
    marginBottom: uh.h2DP(6)
  },
  goals: { marginLeft: uh.h2DP(32), marginTop: uh.h2DP(16) },
  individualAssessmentList: {
    marginTop: uh.h2DP(16),
    marginLeft: uh.h2DP(16),
    marginRight: uh.h2DP(16)
  },
  barchart: {
    marginTop: uh.h2DP(16),
    marginLeft: uh.w2DP(16),
    marginRight: uh.w2DP(16)
  },
  scoreChangeText: { marginTop: uh.h2DP(16), marginLeft: uh.h2DP(16), marginRight: uh.h2DP(16) },
  scoreChange: { marginTop: uh.h2DP(8), marginLeft: uh.h2DP(16), marginRight: uh.h2DP(16) },
  scoreChart: { marginTop: uh.h2DP(24), marginLeft: uh.h2DP(16), marginRight: uh.h2DP(16) },
  learnMore: { marginTop: uh.h2DP(16), marginLeft: uh.h2DP(16), marginRight: uh.h2DP(16) }
});

const BiomarkerDetailModal = ({
  biomarker,
  btnLearnMoreHandler,
  btnBackHandler,
  btnDetailAssessmentClick
}: IBiomarkerDetailProps) => {
  const th = useTheme();
  const today = new Date();
  const moveAnim = useRef(new Animated.Value(uh.height())).current;

  const [scoreFilter, setScoreFilter] = React.useState<{ name: string; filter: number }>({
    name: 'W',
    filter: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).getTime()
  });

  const bottomSheetRef = useRef<BottomSheetModalMethods>(null);
  const favoriteMarkers = useGetFavoriteMarkers();
  const setFavoriteMarkers = useSetFavoriteMarkers();
  const [addFavoriteMarker, isAddingFavoriteMarker, addError] = useAddFavoriteMarkers();
  const [removeFavoriteMarker, isRemovingFavoriteMarker, removeError] = useRemoveFvoriteMarker();
  const startDate = moment('1970-01-01T00:00:01Z').toDate();
  const endDate = moment().endOf('day').toDate();

  const [assessments, markerScoreHistories, isScoreHistoriesLoading, scoreLoadingError] = useMarkerScoreHistoriesEffect(
    { key: String(biomarker.key), startDate: startDate.toISOString(), endDate: endDate.toISOString() }
  );
  const pillars: Array<Pillar> = [{ name: biomarker.pillar, type: biomarker.pillar.toLowerCase() }];

  const appendText = (text: string): string => {
    if (text) {
      return `${text} `;
    }
    return '';
  };

  const addFavoriteBtnClick = async (keys: string[]) => {
    await addFavoriteMarker(keys);
    setFavoriteMarkers([...favoriteMarkers, biomarker]);
  };

  const onDeleteMarkerClick = async () => {
    await removeFavoriteMarker([biomarker.externalKey]);
    setFavoriteMarkers([...favoriteMarkers.filter((marker) => marker.externalKey != biomarker.externalKey)]);
  };

  const removeFavoriteBtnClick = async () => {
    if (!bottomSheetRef.current) {
      return;
    }
    bottomSheetRef.current.present();
    bottomSheetRef.current.snapToIndex(0);
  };

  const moveUp = useCallback(() => {
    // Will decrease value to 30 in 500 milliseconds
    Animated.timing(moveAnim, {
      toValue: uh.topPos(),
      duration: ANIMATE_DURATION,
      useNativeDriver: true
    }).start();
  }, [moveAnim]);

  useEffect(() => {
    moveUp();
  }, [moveUp]);

  const moveDown = (callBackFnc: () => void) => {
    // Will increase value to windowHeight in 500 milliseconds
    Animated.timing(moveAnim, {
      toValue: uh.height(),
      duration: ANIMATE_DURATION,
      useNativeDriver: true
    }).start(() => {
      callBackFnc();
    });
  };

  const btnTellMoreClick = () => {
    moveDown(btnLearnMoreHandler);
  };

  const isFavorite = favoriteMarkers.findIndex((marker) => marker.externalKey === biomarker.externalKey) >= 0;
  const info = appendText(biomarker.infoWhat) + appendText(biomarker.infoHow) + appendText(biomarker.infoWhy);

  const filteredScoreChanges = markerScoreHistories.filter((markerScoreHistory: HealthMarkerHistoryValue) => {
    const date = moment(markerScoreHistory.date);
    return date.isAfter(moment(scoreFilter.filter)) && date.isBefore(moment());
  });

  return (
    <Animated.View
      style={{
        ...styleContainer.animatedViewContainer,
        transform: [
          {
            translateY: moveAnim
          }
        ]
      }}>
      <ModalHeader
        caption="Details"
        btnClickHandler={() => {
          moveDown(btnBackHandler);
        }}
      />

      <KeyboardAvoidingView>
        <Layout level="2" style={[styleContainer.screenContainer]}>
          <Layout style={styleContainer.content}>
            <View style={styleContainer.nameContainer}>
              <Text category="h6" style={styleContainer.name}>
                {biomarker.name}
              </Text>
              {isFavorite ? (
                <TouchableOpacity onPress={removeFavoriteBtnClick} testID={'my_markers_add'}>
                  <DeleteIcon size={22} />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    addFavoriteBtnClick([biomarker.externalKey]);
                  }}
                  testID={'my_markers_add'}>
                  <AddIcon color={th['color-primary-500']} size={22} />
                </TouchableOpacity>
              )}
            </View>
            <AssessmentScore
              score={`${toPreciseNumber(biomarker.displayValue)} ${biomarker.unit}`}
              caption={'Most recent'}
              textCategory="h4"
              style={styleContainer.score}
            />
            <BarChart
              style={styleContainer.barchart}
              healthMarker={biomarker}
              currentValue={Number(biomarker.displayValue)}
            />
            <Divider style={{ ...styleContainer.divider, backgroundColor: th['border-basic-color-3'] }} />
            <AssessmentPillars style={styleContainer.pillars} caption="Pillar" pillars={pillars} size={'large'} />
            <>
              <Divider style={{ ...styleContainer.divider, backgroundColor: th['border-basic-color-3'] }} />
              <Text style={styleContainer.scoreChangeText} category="s1">
                History
              </Text>
              <HistoryFilter
                setFilter={setScoreFilter}
                style={[styleContainer.scoreChange, { marginBottom: uh.h2DP(8) }]}
              />
              {filteredScoreChanges.length > 0 ? (
                <>
                  <ScoreChart
                    style={styleContainer.scoreChart}
                    endDate={endDate}
                    startDate={startDate}
                    currentValue={filteredScoreChanges[filteredScoreChanges.length - 1].value}
                    currentDate={filteredScoreChanges[filteredScoreChanges.length - 1].date}
                    scoreChanges={filteredScoreChanges}
                    filter={scoreFilter.name}
                    unit={biomarker.unit}
                  />
                </>
              ) : (
                <View>
                  <Text category="h5" style={{ textAlign: 'center', marginVertical: uh.h2DP(16) }}>
                    No history records found
                  </Text>
                </View>
              )}
            </>
            {assessments.length > 0 && (
              <>
                <Divider style={{ ...styleContainer.divider, backgroundColor: th['border-basic-color-3'] }} />
                <IndividualAssessmentList
                  style={styleContainer.individualAssessmentList}
                  btnClickHandler={(assessmentId) => {
                    moveDown(() => {
                      btnDetailAssessmentClick(assessmentId);
                    });
                  }}
                  assessments={assessments}
                  caption="Reports this was scored in"
                />
              </>
            )}
            {info.trim().length > 0 && (
              <>
                <Divider style={{ ...styleContainer.divider, backgroundColor: th['border-basic-color-3'] }} />
                <LearnMore
                  style={styleContainer.learnMore}
                  btnHandler={btnTellMoreClick}
                  messages={[info]}
                  useRender={true}
                />
              </>
            )}
            <Spinner visible={isAddingFavoriteMarker || isRemovingFavoriteMarker || isScoreHistoriesLoading} />
          </Layout>
          <ErrorModal
            visible={removeError || addError || scoreLoadingError}
            message={{
              ...removeError,
              ...addError,
              ...scoreLoadingError
            }}
          />
        </Layout>
      </KeyboardAvoidingView>
      <DeleteFavoriteBottomSheet sheetRef={bottomSheetRef} biomarker={biomarker} onBtnClick={onDeleteMarkerClick} />
    </Animated.View>
  );
};

export default BiomarkerDetailModal;
