import { ApplicationState, useAppStore } from 'src/core/store';
import { NavigationContainer, createNavigationContainerRef } from '@react-navigation/native';
import React, { useRef } from 'react';
import { useGetUserAttributes, useIsRequirePublicEmail, useIsUserLogged } from 'src/core/hooks/useUser';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet } from 'react-native';
import moment from 'moment';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useIsFirstRun } from 'src/core/hooks/useGlobalVar';
import RootNavigation from 'src/features/navigation/RootNavigation';
import { NavigationLinking } from 'src/features/navigation/NavConfigForLinking';

let oldTime = moment().seconds();

function AppRootView() {
  const theme = useAppStore((state) => state.theme);
  const viewPortWidth = useAppStore((state) => state.viewPortWidth);

  const { addAnalyticsLog } = useAnalytics('AppRootView.tsx');

  const isFirstRun = useIsFirstRun();
  const isLoggedIn = useIsUserLogged();
  const isRequirePublicEmail = useIsRequirePublicEmail();
  const isInitComplete = useAppStore((state: ApplicationState) => state.isAppInitComplete);
  const userAttributes = useGetUserAttributes();

  const navigationRef = createNavigationContainerRef();
  // styles
  const styleContainer = StyleSheet.create({
    SafeAreaProvider: {
      flex: 1,
      margin: 'auto',
      width: viewPortWidth
    }
  });
  const routeNameRef = useRef<string | undefined>('');

  // view
  return (
    <SafeAreaProvider style={styleContainer.SafeAreaProvider}>
      <NavigationContainer
        linking={NavigationLinking}
        ref={navigationRef as any}
        onReady={() => {
          routeNameRef.current = navigationRef.getCurrentRoute()?.name;
        }}
        onStateChange={async () => {
          const previousRouteName: string | undefined = routeNameRef.current;
          const currentRouteName: string | undefined = navigationRef.getCurrentRoute()?.name;
          if (previousRouteName !== currentRouteName) {
            // record navigation event in Analytics
            addAnalyticsLog({
              data: {
                currentScreen: currentRouteName,
                previousScreen: previousRouteName,
                username: userAttributes?.name
              },
              logType: 'Navigation'
            });
            // log screen duration
            const duration = moment().seconds() - oldTime;
            addAnalyticsLog({
              data: {
                screen: currentRouteName,
                duration: duration < 0 ? 0 : Number(duration)
              },
              logType: 'Screen'
            });
            oldTime = moment().seconds();
          }

          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;
        }}>
        {/* <RootStackNavigation isFirstLoad={isFirstRun} isLoggedIn={isLoggedIn && isInitComplete} /> */}
        <RootNavigation
          isFirstLoad={isFirstRun}
          isLoggedIn={isLoggedIn && isInitComplete}
          isRequirePublicEmail={isRequirePublicEmail}
        />
      </NavigationContainer>
      <StatusBar style={theme == 'light' ? 'dark' : 'light'} />
    </SafeAreaProvider>
  );
}

export default AppRootView;
