import Brands from 'brands.json';

const { chatbot_name: chatbotName, app_prefix: appPrefix, support_website: supportWebsite } = Brands;

export const preSignUpText = {
  collection1Title: 'It’s your health. Be intentional.',
  collection1Description:
    'The journey to optimal health has to combine motivation and consistency with knowledge and personalised guidance. We need to walk the walk in the right direction.\n\n{{app_name}} connects all of your health needs, and delivers to you personalised insights, recommendations, and actions to help create positive habits.',
  collection1Button: 'Next',

  collection2Title: '{{chatbot_name}} is here for you.',
  collection2Description:
    '{{chatbot_name}} will help guide your efforts towards improving your health by relying on your wearable data and conversations with {{chatbot_name}} across our six pillars of health:\n\nMove and Grow, Nourish and Connect, Rest and Reflect.\n\nIf you have been invited to join {{app_name}} by a practitioner, you can also share your data and interact with them directly through the app.',
  collection2Button: 'Let me meet {{chatbot_name}}'
};

export const textAgreementLink = {
  termsOfService: '{{terms_of_service}}',
  privacyPolicy: '{{privacy_policy}}'
};

export const chatBubbleWithAnimation = {
  copyAccessibilityLabel: '{{chatbot_name}} copy button',
  copyTestId: '{{chatbot_name}}-copy-button',

  shareAccessibilityLabel: '{{chatbot_name}} share button',
  shareTestId: '{{chatbot_name}}-share-button'
};

export const navConfigForLinking = {
  appPrefix: `${appPrefix}://`,
  chatbotPath: `${chatbotName}/:biomarker?`
};

export const reviewBloodworkModal = {
  doneDescription: 'Your bloodwork is now reflected in your {{app_name}} Score.'
};

export const scanGuideDescription = {
  dataHandlingInfoMessage: "Read more about {{app_name}}'s [Data Handling]({{data_handling_website}}) Policy."
};

export const terraInfoModal = {
  terraInfoMessage:
    "{{app_name}} uses Terra as a partner company to consolidate and process wearable data. Terra does not store any of your data. The data is sent directly to {{app_name}} after secure authentication. You can read Terra's privacy policy here: "
};

export const needUpdateApp = {
  needUpdateAlertContent: 'Please use the latest version of {{app_name}}.',
  needUpdateAlertTitle: 'Update required',
  needUpdateAlertBtn: 'Update'
};

export const confirmationRegisterOTPScreen = {
  headerMessage1Title: 'We are one step closer to connect you with {{app_name}}!',
  headerMessage1WelcomeCaption: 'Please enter the account registered email.',
  headerMessage2Title: 'Great, nearly there! We have verified your email and sent you an OTP code.',
  headerMessage2WelcomeCaption: 'Type in the OTP code to join {{app_name}}.'
};

export const confirmationRegisterScreen = {
  confirmMessageTitle: 'Thank you for your patience. Your registration was successful.',
  confirmMessageCaption: 'Welcome to {{app_name}}. Click the button below to log in.',
  confirmMessageButton: 'Continue'
};

export const confirmationResetScreen = {
  confirmMessageTitle: 'Thank you for your patience. Your password reset was successful.',
  confirmMessageCaption: 'Welcome back to {{app_name}}. Click the button below to log in.',
  confirmMessageButton: 'Continue'
};

export const resetPasswordOTPScreen = {
  welcomeTitle: 'Great, nearly there! We have verified your email and sent you an OTP code.',
  welcomeCaption: 'Type in the OTP code and your new password to get back into {{app_name}}.'
};

export const resetPasswordScreen = {
  welcomeTitle: "Dont worry, we'll get you back into {{app_name}}.",
  welcomeCaption: 'Please provide us with your email for verification.'
};

export const homeScreen = {
  nbaQuote: 'Talk to {{chatbot_name}}'
};

export const kaliChatScreen = {
  debugId: '{{app_name}}-debug-assistant-id'
};

export const scanGettingStartedScreen = {
  membershipInfoMessage: '{{app_name}} membership required'
};

export const faceScanGuideScreen = {
  permissionMessage:
    'The camera permissions are blocked.\nTo continue, please allow {{app_name}} access to your camera.'
};

export const feedbackScreen = {
  feedbackPlaceholder: 'Help us make {{app_name}} better.'
};

export const backendApi = {
  authErrorTitle: 'There was an authentication error.',
  authErrorMessage: `Please sign in again.\nIf the issue persists, contact us at ${supportWebsite}.`,
  webAuthErrorMessage: `There was an authentication error.\nPlease sign in again.\nIf the issue persists, contact us at ${supportWebsite}.`
};

export const scan = {
  permissionMessage:
    'The camera permissions are blocked.\nTo continue, please allow {{app_name}} access to your camera.'
};

export const errorModal = {
  errorHeader: 'There was a problem processing the request.',
  errorBody: 'There was an unexpected error. Please send the report.',
  errorSendReportBtn: 'Report'
};

export const bottomMenu = {
  about: 'About',
  feedback: 'Feedback'
};

export const mainMenuContainer = {
  mainMenuYourActivity: 'YOUR ACTIVITY',
  mainMenuActions: 'Actions',
  mainMenuScan: 'Scan',
  mainMenuAssessments: 'Reports',
  mainMenuMarkersOfInterest: 'Markers',
  mainMenuTalkToKali: '{{chatbot_name}}',
  mainMenuSettings: 'SETTINGS',
  mainMenuProfile: 'Profile',
  mainMenuSubscriptions: 'Subscriptions',
  mainMenuAccount: 'Account',
  mainMenuDataSharing: 'Data Sharing',
  mainMenuWearables: 'Wearables',
  mainMenuCustomisation: 'Customisation',
  mainMenuAbout: 'About',
  mainMenuFeedback: 'Feedback',
  mainMenuLogOut: 'Log Out',
  mainMenuDeveloperSettings: 'Developer'
};

export const scoreSummary = {
  learnMoreAboutNourish:
    'The Nourish score attempts to capture how well nourished you are. This relates to what, when, and how much you eat and drink, but also to everything from your hydration and digestion to the status of your blood biomarkers.',
  learnMoreAboutMove:
    'The Move score attempts to capture how the systems of your body perform: the cardiovascular, respiratory, muscular, and skeletal systems. This includes vital functions like blood pressure, resting heart rate, heart rate variability, and respiratory rate, as well as mobility, strength, and endurance with assessments that go from simple questions to detailed measurements in your gym or clinic.',
  learnMoreAboutConnect:
    'The Connect score includes the various facets of your relationships covering your intimate relationship with your life partner if you have one, immediate and extended family, close and more distant friends, colleagues, and community in a way that attempts to evaluate their impact on your health, specifically for you, because we are all different and we each value and need connections differently.',
  learnMoreAboutGrow:
    'The Grow score is about personal growth. It is about what we do to help ourselves grow and evolve in the way we want to see ourselves in the future. It is about how we want to be, and where we want to go. This covers values, goals, motivation, inspiration, and role models.',
  learnMoreAboutReflect:
    'The Reflect score attempts to capture how we take the time to pay attention. It is about being mindful in the broadest sense of the word. To feel the breath move in and out. To feel the hands and the feet. To feel the details of the sensations we feel. How we see states of mind, movements of attention towards or away from thoughts, memories, and stories we tell ourselves about ourselves and others. How grateful we feel for what we have. And how we listen to and communicate with people.',
  learnMoreAboutRest:
    'The Rest score attempts to capture how well rested you are. This is related to how well you sleep, both objectively and subjectively. Objective measures include things like how long, how much of the different types of sleep you get, how consistent and how continuous your sleep is. Subjective measures are things like how rested you feel in the morning, how often you feel sleepy, and how your energy is at different times of the day.',
  learnMoreAboutAccuracy:
    'All scores are estimated based on what you have shared through wearable data and conversations. Accuracy is a measure of how well the score is estimated. This is a function of how much information is included in the score calculation: less information results in lower accuracy, and more information translates into better accuracy.',
  learnMoreAboutDefault:
    'Your score is intended as a general estimate of your overall state of health based on all the information we have about you. It combines all scores across all categories throughout the system using a weighted averaging proportional to impact on health. The things that have a greater impact on your health are given more weight, and those with a lesser impact are given less.'
};
