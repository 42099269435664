import UserService from 'src/api/user';
import { useAnalytics } from '../useAnalytics';
import { useEffect } from 'react';
import { useIsUserLogged, useSetDataSharingList } from '../useUser';

export const useInitDataSharingList = () => {
  const setDatasharingList = useSetDataSharingList();
  const { addAnalyticsLog } = useAnalytics('useInitDataSharingList');
  const isUserLogged = useIsUserLogged();

  useEffect(() => {
    if (isUserLogged) {
      UserService.getDataSharingList()
        .then((res) => {
          setDatasharingList(res);
        })
        .catch((err) => addAnalyticsLog({ function: 'useInitDataSharingList', data: err, logType: 'error' }));
    }
  }, [addAnalyticsLog, isUserLogged, setDatasharingList]);
};
