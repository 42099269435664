import React, { useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Button, Card, Modal, Text, Input } from '@ui-kitten/components';
import { emailValidator, UIHelper as uh } from '../../core';
import { ProfileDataFormError } from 'src/core/types/AuthError';
import { ErrorMessage } from './ErrorMessage';
import UserService from 'src/api/user';
import { Auth } from 'aws-amplify';

interface IInputEmailModalProps extends ViewProps {
  visible: boolean;
  message: string | JSX.Element;
  buttonTitle?: string;
  closeBtnClick: () => void;
  noBtnClick?: () => void;
  secondaryButtonClick?: () => void;
  secondaryButtonTitle?: string;
  testingAttributes?: {
    accessible: boolean;
    accessibilityLabel: string;
    testID: string;
  };
}

export const InputEmailModal = (props: IInputEmailModalProps) => {
  const styles = StyleSheet.create({
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    button: { width: 80, alignSelf: 'center', marginTop: 20, marginLeft: 80, marginRight: 60 },
    buttonStyle: {
      width: 140,
      marginHorizontal: 10
    },
    message: {
      maxWidth: uh.currentViewPort() - 32,
      textAlign: 'center'
    },
    card: { maxWidth: uh.currentViewPort() - 32 },
    buttonArea: { justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 20, rowGap: 26 }
  });

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<ProfileDataFormError>({} as ProfileDataFormError);

  const savePublicEmail = async () => {
    const newError: ProfileDataFormError = {} as ProfileDataFormError;

    const emailError = emailValidator(email);
    if (emailError) {
      newError.email = emailError;
    }
    setError(newError);

    const user = await Auth.currentAuthenticatedUser();

    await UserService.updatePublicEmail(user.attributes.sub, email);
    props.closeBtnClick();
  };

  return (
    <Modal
      visible={props.visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => props.noBtnClick && props.noBtnClick()}
      accessible={props.testingAttributes?.accessible || undefined}
      accessibilityLabel={props.testingAttributes?.accessibilityLabel || undefined}
      testID={props.testingAttributes?.testID || undefined}>
      <Card style={styles.card}>
        {typeof props.message === 'string' ? <Text style={styles.message}>{props.message}</Text> : props.message}
        <View style={styles.buttonArea}>
          <ErrorMessage message={error.email} />
          <Input
            size="large"
            status={error.email ? 'danger' : 'basic'}
            //style={styleContainer.input}
            label="Public Email"
            value={email}
            onChangeText={setEmail}
            keyboardType={'email-address'}
            autoCapitalize="none"
            autoCorrect={false}
            accessible={true}
          />

          {props.secondaryButtonClick && (
            <Button
              style={styles.buttonStyle}
              appearance="ghost"
              size="medium"
              onPress={() => props.secondaryButtonClick && props.secondaryButtonClick()}>
              {props.secondaryButtonTitle}
            </Button>
          )}
          <Button
            style={styles.buttonStyle}
            size="medium"
            onPress={() => {
              savePublicEmail();
            }}>
            {props.buttonTitle == undefined ? 'Save' : props.buttonTitle}
          </Button>
        </View>
      </Card>
    </Modal>
  );
};
