import UserService from 'src/api/user';
import { useAnalytics } from '../useAnalytics';
import { useEffect } from 'react';
import { useIsUserLogged, useSetDataSharingRequestList } from '../useUser';

export const useInitDataSharingRequestList = () => {
  const setDatasharingRequestList = useSetDataSharingRequestList();
  const { addAnalyticsLog } = useAnalytics('useInitDataSharingRequestList');
  const isUserLogged = useIsUserLogged();

  useEffect(() => {
    if (isUserLogged) {
      UserService.getDataSharingRequestList()
        .then((res) => {
          setDatasharingRequestList(res);
        })
        .catch((err) => addAnalyticsLog({ function: 'useInitDataSharingList', data: err, logType: 'error' }));
    }
  }, [addAnalyticsLog, isUserLogged, setDatasharingRequestList]);
};
