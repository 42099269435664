import { Layout } from '@ui-kitten/components';
import { Platform, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { YourAssessments } from '../../components/profileMeAssessment';
import { RootTabScreenProps, TenantFeature, UIHelper as uh } from '../../core';
import { useLoadHealthMarkerUnits } from 'src/core/hooks/useAssessments';

import DisableTenantFeature from 'src/components/shared/DisableTenantFeature';
import RefreshControl from 'src/components/shared/RefreshControl';
import { StackActions } from '@react-navigation/native';
import { mainStyles } from './_mainStyles';
import { useGetTenantFeatures } from 'src/core/hooks/useUser';

let notiDocId = '';
const ProfileMeAssessmentScreen = ({ route, navigation }: RootTabScreenProps<any>) => {
  const tenantFeatures: TenantFeature[] = useGetTenantFeatures();

  useLoadHealthMarkerUnits();

  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    container: {
      flex: 1
    },
    learnMore: { marginTop: uh.h2DP(40) },
    divider: { marginTop: uh.h2DP(16), marginBottom: uh.h2DP(16) },
    chatQuote: { marginTop: uh.h2DP(16) },
    btnUpload: { marginTop: uh.h2DP(16) },
    btnInfo: { marginTop: uh.h2DP(18) },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [isMeAssessmentScreenEnable, setIsMeAssessmentScreenEnable] = useState(true);

  const btnDetailAssessmentClick = (assessmentId: string) => {
    if (Platform.OS === 'android')
      navigation.dispatch(
        StackActions.replace('AssessmentDetail', { backToScreen: 'Profile', assessmentId: assessmentId })
      );
    else
      navigation.navigate('AssessmentDetail' as any, {
        assessmentId: assessmentId
      });
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  useEffect(() => {
    const isEnableAssessment =
      tenantFeatures.find((item: TenantFeature) => item.key == 'DailyMetricsReportFeature') != undefined;
    setIsMeAssessmentScreenEnable(isEnableAssessment);
  }, [tenantFeatures]);

  useEffect(() => {
    const id = (route?.params as any)?.pendingDocId;
    if (id != undefined && id.length > 0 && id != notiDocId) {
      if (id != undefined && id.length > 0 && id != notiDocId) {
        notiDocId = id;
        navigation.navigate('AssessmentDetail' as any, { assessmentId: notiDocId });
      }
    }
  }, [route?.params, navigation]);

  return (
    <Layout level="2" style={[styleContainer.screenContainer]}>
      <ScrollView
        contentContainerStyle={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        {isMeAssessmentScreenEnable ? (
          <SafeAreaView style={styleContainer.screenContainer}>
            <YourAssessments
              btnDetailAssessmentClick={btnDetailAssessmentClick}
              refreshing={refreshing}
              finishRefreshing={() => {}}
            />
          </SafeAreaView>
        ) : (
          <DisableTenantFeature />
        )}
      </ScrollView>
    </Layout>
  );
};

export default ProfileMeAssessmentScreen;
